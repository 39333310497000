<app-content title="Laatste resultaten">
  <div outside-content-container>

  </div>
    <app-results [numberOfResultsToShow]="3" [componentPadding]="false"></app-results>
</app-content>

<app-content title="Voorinschrijven">
  <div outside-content-container>

  </div>
  <app-subscribe [numberOfEventsToShow]="3" [componentPadding]="false"></app-subscribe>
</app-content>


